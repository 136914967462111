(function( $ ) {
	
	const $hamburger = $('.hamburger');
	const $nav = $('.site-nav');
	const $logo = $('.logo-mobile');

	$hamburger.on('click', () => {
		if ( $nav.hasClass('is-open') ) {
			CloseMenu();
		} else {
			OpenMenu();
		}
	});

	function OpenMenu() {
		$hamburger.addClass('is-active');
		$logo.addClass('menu-open');
		$nav.addClass('is-open');
	}

	function CloseMenu() {
		$hamburger.removeClass('is-active');
		$logo.removeClass('menu-open');
		$nav.removeClass('is-open');
	}

	const $submenu_toggle = $('.js-toggle');

	$submenu_toggle.on('click', function() {
		var dropdown = $(this).next('.dropdown');

		if ( dropdown.hasClass('active') ) {
			dropdown.removeClass('active');
			$(this).removeClass('rotate');
		} else {
			dropdown.addClass('active');
			$(this).addClass('rotate');
		}
	});

})( jQuery );
