(function( $ ) {
	
	const $project_gallery = $('.slider--project');
    const $project_gallery_slider = $project_gallery.find('.js-slider');
    const $project_gallery_buttons = $project_gallery.find('.slider__buttons');

    $project_gallery_slider.slick({
        slidesToShow: 1,
        slidesToScroll: 1,
        infinite: true,
        adaptiveHeight: true,
        nextArrow: $project_gallery_buttons.find('.next'),
        prevArrow: $project_gallery_buttons.find('.prev')
    });

    const $testimonials = $('.slider--testimonials');
    const $testimonials_slider = $testimonials.find('.js-slider');
    const $testimonials_buttons = $testimonials.find('.slider__buttons');

    $testimonials_slider.slick({
        slidesToShow: 1,
        slidesToScroll: 1,
        speed: 1,
        infinite: true,
        adaptiveHeight: true,
        nextArrow: $testimonials_buttons.find('.next'),
        prevArrow: $testimonials_buttons.find('.prev')
    });

    const $latest_news = $('.slider--news');
    const $latest_news_slider = $latest_news.find('.js-slider');
    const $latest_news_buttons = $latest_news.find('.slider__buttons');

    $latest_news_slider.slick({
        slidesToShow: 3,
        slidesToScroll: 1,
        infinite: true,
        nextArrow: $latest_news_buttons.find('.next'),
        prevArrow: $latest_news_buttons.find('.prev'),
        responsive: [
            {
              breakpoint: 991,
              settings: {
                slidesToShow: 2,
              }
            },
            {
              breakpoint: 580,
              settings: {
                slidesToShow: 1,
              }
            }
            // You can unslick at a given breakpoint now by adding:
            // settings: "unslick"
            // instead of a settings object
          ]
    });

    const $clients = $('.slider--clients');
    const $clients_slider = $clients.find('.js-slider');
    const $clients_buttons = $clients.find('.slider__buttons');

    $clients_slider.slick({
        slidesToShow: 6,
        slidesToScroll: 1,
        infinite: true,
        nextArrow: $clients_buttons.find('.next'),
        prevArrow: $clients_buttons.find('.prev'),
        responsive: [
          {
            breakpoint: 1199,
            settings: {
              slidesToShow: 5,
            }
          },
          {
            breakpoint: 991,
            settings: {
              slidesToShow: 4,
            }
          },
          {
            breakpoint: 767,
            settings: {
              slidesToShow: 3,
            }
          },
          {
            breakpoint: 580,
            settings: {
              slidesToShow: 2,
            }
          }
          // You can unslick at a given breakpoint now by adding:
          // settings: "unslick"
          // instead of a settings object
        ]
    });

})( jQuery );
