(function( $ ) {

    const accordion = $('.accordion-container');

    if ( accordion.length ) {
        new Accordion('.accordion-container', {
            openOnInit: [0]
        });
    }

})( jQuery );